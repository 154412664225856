$shield: polygon(
  51.613% 0%,
  51.613% 0%,
  51.891% 0.006%,
  52.169% 0.024%,
  52.445% 0.053%,
  52.721% 0.094%,
  52.994% 0.147%,
  53.265% 0.21%,
  53.533% 0.285%,
  53.797% 0.371%,
  54.058% 0.468%,
  54.315% 0.576%,
  92.278% 16.455%,
  92.278% 16.455%,
  93.573% 17.081%,
  94.786% 17.847%,
  95.906% 18.741%,
  96.918% 19.755%,
  97.81% 20.879%,
  98.568% 22.104%,
  99.179% 23.42%,
  99.63% 24.818%,
  99.908% 26.288%,
  100% 27.822%,
  100% 27.822%,
  99.708% 34.191%,
  98.817% 41.325%,
  97.219% 49.008%,
  94.802% 57.023%,
  91.457% 65.156%,
  87.072% 73.188%,
  81.538% 80.905%,
  74.744% 88.09%,
  66.58% 94.527%,
  56.935% 100%,
  56.935% 100%,
  55.91% 100.429%,
  54.859% 100.763%,
  53.788% 101.002%,
  52.704% 101.145%,
  51.613% 101.192%,
  50.522% 101.145%,
  49.438% 101.002%,
  48.367% 100.763%,
  47.316% 100.429%,
  46.29% 100%,
  46.29% 100%,
  36.646% 94.527%,
  28.482% 88.09%,
  21.688% 80.905%,
  16.154% 73.188%,
  11.769% 65.156%,
  8.424% 57.023%,
  6.007% 49.008%,
  4.408% 41.325%,
  3.518% 34.191%,
  3.226% 27.822%,
  3.226% 27.822%,
  3.317% 26.288%,
  3.595% 24.818%,
  4.047% 23.42%,
  4.658% 22.104%,
  5.416% 20.879%,
  6.308% 19.755%,
  7.32% 18.741%,
  8.439% 17.847%,
  9.653% 17.081%,
  10.948% 16.455%,
  48.931% 0.576%,
  48.931% 0.576%,
  49.182% 0.468%,
  49.439% 0.371%,
  49.7% 0.285%,
  49.965% 0.21%,
  50.234% 0.147%,
  50.506% 0.094%,
  50.781% 0.053%,
  51.057% 0.024%,
  51.335% 0.006%,
  51.613% 0%
);

.menu {
  max-height: calc(100vh - 48px);
}

.emoji-border {
  &::before {
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-image:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask-composite: exclude;
  }
}

.emoji-shield {
  clip-path: polygon(
    51.613% 0%,
    51.613% 0%,
    51.891% 0.006%,
    52.169% 0.024%,
    52.445% 0.053%,
    52.721% 0.094%,
    52.994% 0.147%,
    53.265% 0.21%,
    53.533% 0.285%,
    53.797% 0.371%,
    54.058% 0.468%,
    54.315% 0.576%,
    92.278% 16.455%,
    92.278% 16.455%,
    93.573% 17.081%,
    94.786% 17.847%,
    95.906% 18.741%,
    96.918% 19.755%,
    97.81% 20.879%,
    98.568% 22.104%,
    99.179% 23.42%,
    99.63% 24.818%,
    99.908% 26.288%,
    100% 27.822%,
    100% 27.822%,
    99.708% 34.191%,
    98.817% 41.325%,
    97.219% 49.008%,
    94.802% 57.023%,
    91.457% 65.156%,
    87.072% 73.188%,
    81.538% 80.905%,
    74.744% 88.09%,
    66.58% 94.527%,
    56.935% 100%,
    56.935% 100%,
    55.91% 100.429%,
    54.859% 100.763%,
    53.788% 101.002%,
    52.704% 101.145%,
    51.613% 101.192%,
    50.522% 101.145%,
    49.438% 101.002%,
    48.367% 100.763%,
    47.316% 100.429%,
    46.29% 100%,
    46.29% 100%,
    36.646% 94.527%,
    28.482% 88.09%,
    21.688% 80.905%,
    16.154% 73.188%,
    11.769% 65.156%,
    8.424% 57.023%,
    6.007% 49.008%,
    4.408% 41.325%,
    3.518% 34.191%,
    3.226% 27.822%,
    3.226% 27.822%,
    3.317% 26.288%,
    3.595% 24.818%,
    4.047% 23.42%,
    4.658% 22.104%,
    5.416% 20.879%,
    6.308% 19.755%,
    7.32% 18.741%,
    8.439% 17.847%,
    9.653% 17.081%,
    10.948% 16.455%,
    48.931% 0.576%,
    48.931% 0.576%,
    49.182% 0.468%,
    49.439% 0.371%,
    49.7% 0.285%,
    49.965% 0.21%,
    50.234% 0.147%,
    50.506% 0.094%,
    50.781% 0.053%,
    51.057% 0.024%,
    51.335% 0.006%,
    51.613% 0%
  );
}

.chips {
  -ms-overflow-style: none; /* IE and Edge */
  -webkit-mask: linear-gradient(
    to right,
    transparent,
    black theme("spacing.2"),
    black calc(100% - theme("spacing.2")),
    transparent 100%
  );
  mask-image: linear-gradient(
    to right,
    transparent,
    black theme("spacing.2"),
    black calc(100% - theme("spacing.2")),
    transparent 100%
  );
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

.calendar {
  &__day {
    &--first {
      &::before {
        -webkit-mask: linear-gradient(to left, black 50%, transparent 100%);
        mask-image: linear-gradient(to left, black 50%, transparent 100%);
      }
    }
    &--last {
      &::before {
        -webkit-mask: linear-gradient(to right, black 50%, transparent 100%);
        mask-image: linear-gradient(to right, black 50%, transparent 100%);
      }
    }
  }
}

.details {
  &--scrollable {
    -webkit-mask: linear-gradient(
      to bottom,
      transparent,
      black theme("spacing.8"),
      black calc(100% - theme("spacing.8")),
      transparent 100%
    );
    mask-image: linear-gradient(
      to bottom,
      transparent,
      black theme("spacing.8"),
      black calc(100% - theme("spacing.8")),
      transparent 100%
    );
  }

  &--at-top {
    -webkit-mask: linear-gradient(
      to bottom,
      black,
      black calc(100% - theme("spacing.8")),
      transparent 100%
    );
    mask-image: linear-gradient(
      to bottom,
      black,
      black calc(100% - theme("spacing.8")),
      transparent 100%
    );
  }

  &--at-bottom {
    -webkit-mask: linear-gradient(
      to bottom,
      transparent,
      black theme("spacing.8"),
      black 100%
    );
    mask-image: linear-gradient(
      to bottom,
      transparent,
      black theme("spacing.8"),
      black 100%
    );
  }
}

.form-checkbox:checked {
  background-image: url("./assets/images/checkbox.svg") !important;
}

img[src=""] {
  opacity: 0;
}
